@tailwind base;
@tailwind components;
@tailwind utilities;

.noKorean {
  -webkit-ime-mode: active;
  -moz-ime-mode: active;
  -ms-ime-mode: active;
  ime-mode: active;
}

.select_bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select_bar::-ms-expand {
  display: none;
}

.web_inner_html br {
  content: '';
  height: 15px;
  display: inline-block;
}

.mobile_inner_html {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mobile_inner_html img {
  margin: 0 -1rem;
  width: 100vw;
  max-width: 100vw;
}

.mobile_inner_html a {
  color: blue;
  text-decoration: underline;
}
.web_inner_html a {
  color: blue;
  text-decoration: underline;
}
.mobile_inner_html br {
  content: '';
  height: 15px;
  display: inline-block;
}
.web_inner_html h1 {
  font-size: 2em;
}
.web_inner_html h2 {
  font-size: 1.5em;
}
.web_inner_html h3 {
  font-size: 1.17em;
}
.mobile_inner_html h1 {
  font-size: 2em;
}
.mobile_inner_html h2 {
  font-size: 1.5em;
}
.mobile_inner_html h3 {
  font-size: 1.17em;
}
.ballon {
  position: absolute;
  background: #6b7280;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10px;
  bottom: 30px;
  border-radius: 5px;
  color: #fff;
  padding: 12px 12.8px;
  width: 235px;
  height: 40px;
  font-size: small;
}
.ballon::after {
  border-top: 10px solid #6b7280;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  bottom: -9px;
  left: 12px;
}
